import React from "react";
import ReactDOM from "react-dom/client";
import Matemachitel from "./components/MatemachitelPage/matemachitel.js";

import GlobalStyle from "./globalStyles";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Matemachitel />
  </React.StrictMode>
);
