import React from "react";
import { allTeachers } from "../../assets/data/teacherInfo";
import Card from "../Card/card";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import {
  StyledTeachersContainer,
  Title,
  StyledSlider,
  SliderParent,
  TeachersDescription1,
  TeacherDescription2,
} from "./teachersElements";

const Teachers = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 2000,
    arrows: true,
    lazyLoad: "progressive",
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <StyledTeachersContainer id={"teachers"}>
      <Title>Преподаватели</Title>
      <SliderParent>
        <StyledSlider {...settings}>
          {allTeachers.map((teacher, index) => (
            <Card
              key={index}
              imgSrc={teacher.image}
              name={teacher.name}
              description={teacher.description}
              learnMoreInfo={teacher.learnMoreInfo}
            />
          ))}
        </StyledSlider>
      </SliderParent>
      <TeachersDescription1>
        Тук можете да намерите повече информация за нашите учители и да изберете
        този, който пасва на Вашите желания и цели. Всеки един член на екипа на
        МатемачителБГ има богат опит зад гърба си и се стреми да даде
        най-доброто образование на Вашето дете, а на Вас оставяме избора как и
        при кого искате да се запишете.
      </TeachersDescription1>
      {/*       <TeacherDescription2>
        *Ако искате да резервирате обучение с един от нашите ментори, свържете
        се с нас на посочения номер в контакти*
      </TeacherDescription2> */}
    </StyledTeachersContainer>
  );
}; 

export default Teachers;
