export const PAGE_FONTS = {
  TITLE_FONT: "Rubik Bubbles",  
  SECTIONS_FONT: "Comfortaa",
  CARD_FONT: "Montserrat",
  BASIC_FONT: "Poiret One",
  NUNITO_FONT: "Nunito",
  OSWALD_FONT: "Oswald",
};

export const WINDOW_WIDTH_CONSTRAINTS = {
  SMALL_SCREENS_UP_LIMIT: "481px",
  SMALL_SCREENS_DOWN_LIMIT: "480px",
  MEDIUM_SCREENS_DOWN_LIMIT: "728px",
  MEDIUM_SCREENS_UP_LIMIT: "729px",
  LARGE_SCREENS_UP_LIMIT: "1025px",
  LARGE_SCREENS_DOWN_LIMIT: "1024px",
};
