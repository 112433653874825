import React from "react";
import Maps from "../Maps/maps";

import { GoLocation } from "react-icons/go";
import { BsTelephone } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import {
  StyledContactsContainer,
  Info,
  Address,
  MobileNumberContent,
  PhoneNumber,
  MapsWrapper,
  ContactsWrapper,
  ContactsInfoItemWrapper,
  ContactsImageWrapper,
  StyledContactsImage,
  ContactData,
  FacebookLink
} from "./contactsElements";
import { Title } from "../Teachers/teachersElements";

import ContactUsImg from "../../assets/photos/draw_ppl.png";
import { contactsData } from "../../assets/data/contactsInfo";

const Contacts = () => {
  return (
    <StyledContactsContainer id={"contacts"}>
      <Info>
        <Title>Контакти</Title>
        <ContactsWrapper>
          <ContactsInfoItemWrapper>
            <GoLocation size={20}/>
            <ContactData>{contactsData.address}</ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <ContactData>{contactsData.address2}</ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <BsTelephone size={20} />
            <ContactData>{contactsData.telephone}</ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <FaFacebookSquare size={24} />
            <ContactData>
              <FacebookLink
                href={contactsData.facebook}
                alt={contactsData.facebookAltMessage}
              >
                MatemachitelBook
              </FacebookLink>
            </ContactData>
          </ContactsInfoItemWrapper>
        </ContactsWrapper>
        <MapsWrapper>
          <Maps />
        </MapsWrapper>
        {/* <StyledContactsImage src={ContactUsImg} /> */}
      </Info>
      <ContactsImageWrapper>
        {/* <MapsWrapper> */}
        {/* <Maps /> */}
        <StyledContactsImage src={ContactUsImg} />
        {/* </MapsWrapper> */}
      </ContactsImageWrapper>
    </StyledContactsContainer>
  );
};

export default Contacts;
