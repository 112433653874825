import React from "react";
import { Map } from "./mapsElements";

const Maps = () => {
  return (
    <Map
      src={
        "https://maps.google.com/maps?q=ulitsa%20%22Sv.%20Sveti%20Kiril%20I%20Metodiy%22%2027,%201202%20Sofia%20Center,%20Sofia&t=&z=13&ie=UTF8&iwloc=&output=embed"
      }
      scrolling={"no"}
      referrerpolicy={"no-referrer-when-downgrade"}
    />
  );
};

export default Maps;
