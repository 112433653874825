import React from "react";
import {
  SidebarContainer,
  CloseIconWrapper,
  CloseIcon,
  SidebarWrapper,
  SidebarLink,
  SidebarMenu,
} from "./sidebarElements";

const SideBar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <CloseIconWrapper onClick={toggle}>
        <CloseIcon />
      </CloseIconWrapper>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="teachers" onClick={toggle}>
            Преподаватели
          </SidebarLink>
          <SidebarLink to="about" onClick={toggle}>
            За нас
          </SidebarLink>
          <SidebarLink to="contacts" onClick={toggle}>
            Контакти
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default SideBar;
