import React from "react";
import { FaBars } from "react-icons/fa";
import Logo from "../Logo/logo";

import {
  NavbarContainer,
  Nav,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavLogoItem,
} from "./navbarElements";

const Navbar = ({ toggle }) => {
  return (
    <Nav>
      <NavbarContainer>
        <NavLogoItem>
          <Logo />
        </NavLogoItem>
        <MobileIcon onClick={toggle}>
          <FaBars color="#2b2e35" />
        </MobileIcon>
        <NavMenu>
          <NavItem>
            <NavLinks to="teachers"> Преподаватели </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="about"> За нас </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="contacts"> Контакти </NavLinks>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
