import styled from "styled-components/macro";

export const Map = styled.iframe`
  width: 100%;
  height: 100%;

  max-width: 400px;
  max-height: 270px;

  border-radius: 25px;
`;
