import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
*, *::after, *::before {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;   
}
 html, body{
    margin: 0;
    padding: 0;
 }

`;

export default GlobalStyle;
