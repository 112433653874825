import React from "react";
import { useState } from "react";
import AboutUs from "../AboutUs/aboutUs";
import Contacts from "../Contacts/contacts";
import Navbar from "../Navigation/navbar";
import SideBar from "../Sidebar/sidebar";
import Teachers from "../Teachers/teachers";
import HomePage from "../HomePage/homePage";

const Matemachitel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HomePage />
      <Teachers />
      <AboutUs />
      <Contacts />
    </div>
  );
};

export default Matemachitel;
