import React from "react";
import AboutUsImage from "../../assets/photos/twoppl.png";
import TextFrame from "../../assets/photos/frame.png";

import {
  Description,
  StyledAboutUsContainer,
  ImageWrapper,
  InfoWrappper,
  StyledImage,
  TextFrameWrapper,
} from "./aboutUsElements";
import { Title } from "../Teachers/teachersElements";

const AboutUs = () => {
  return (
    <StyledAboutUsContainer id={"about"}>
      <ImageWrapper>
        <StyledImage src={AboutUsImage} />
      </ImageWrapper>
      <InfoWrappper>
        <Title isPrimary>За нас</Title>

        <Description>
          Преподавателите в образователен център “БГ Ментор” са млади хора,
          които от ранна детска възраст са в сферата на математиката и информатиката (бивши възпитаници на СМГ и ФМИ). 
          Те са отлично запознати с училищния материал, както и с всички новости в областта.
          В продълкжение на 4 години вече нашите ментори подготвят ученици на всякаква възраст. 
        </Description>

        <Description>
          Ние винаги се стараем да преподаваме не само конвенционално
          (както в училище), а като се осъществяват логически връзки и материалът се разбира в дълбочина.
          По този начин материята се усвоява, а не се наизустява. Нашият
          разчупен подход и много общи теми ни сближават с учениците. Изграждаме доверие и комуникацията е значително по-лесна, което
          предразполага учениците да задават въпроси. Така ние преодоляваме най-често срещаната бариера между учител и ученик,
          защото тази бариера възпрепятства именно изчистването на пропуските им.
        </Description>
      </InfoWrappper>
    </StyledAboutUsContainer>
  );
};

export default AboutUs;
