import styled from "styled-components/macro";
import { FaTimes } from "react-icons/fa";
import { Link as LinkS } from "react-scroll";
import { PAGE_FONTS } from "../../constants";

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #bcbcbc;

  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;

  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const CloseIcon = styled(FaTimes)`
  color: #ffffff;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  // TO DO: Do we need outline:none really?
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #bcbcbc;
`;

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const SidebarLink = styled(LinkS)`
  display: flex;
  justify-content: center;

  color: #ffffff;
  font-size: 2rem;
  font-family: ${PAGE_FONTS.BASIC_FONT};
  font-weight: bold;

  list-style: none;
  transition: 0.2s ease-in-out;

  cursor: pointer;

  &:hover,
  :active {
    text-decoration: underline;
    transition: 0.2s ease-in-out;
  }
`;
