import styled from "styled-components/macro";
import { PAGE_FONTS } from "../../constants";

export const Wrapper = styled.div`
  border-radius: 25px;
  /* width: 60%; */

  background-color: #CBC3F7;
`;

export const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
  height: 10%;

  position: relative;
  /* row-gap: 5px; */
`;

export const Overlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #19173C;
  border-radius: 25px 25px 0 25px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -40px;
    height: 40px;
    width: 40px;
    background: #19173C;
  }

  &:after {
    border-radius: 0 25px 0 0;
    background-color: #CBC3F7;
  }
`;

export const CardImageWrapper = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 3px;
  background: #efefef;
`;

export const CardImage = styled.img`
  height: 100%;
  width: 100%;

  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #19173C;
`;

export const TeacherName = styled.h2`
  font-family: ${PAGE_FONTS.CARD_FONT};

  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  text-align: center;

  // Because we don't want to have too long names
  // hidden behind styles from the card top
  /* z-index: 15; */
`;

export const TeacherDescription = styled.p`
  color: rgba(0, 0, 0, 0.63);
  font-family: ${PAGE_FONTS.CARD_FONT};

  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
`;

export const ViewMoreButton = styled.button`
  border: none;
  font-family: ${PAGE_FONTS.CARD_FONT};
  font-size: 14px;
  color: white;
  background: #19173C;

  border: 3px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;

  padding: 8px 16px;
  margin: 14px;

  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    background: #19173C;
    color: #D6F082;
    border: 3px solid #D6F082;

  }
`;

export const CardContent = styled.div`
  // TO DO - this 4 lines are the same as 4 in image content
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
`;

export const AdditionalDescription = styled.p`
  font-family: ${PAGE_FONTS.CARD_FONT};

  color: #000000;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  margin: 25px;
  max-height: 100px;

  overflow-wrap: break-word;
  overflow-y: scroll;

  // Because we don't want to have too long names
  // hidden behind styles from the card top
  /* z-index: 15; */
`;

export const Price = styled.p`
  font-size: 13px;
  text-align: center;
  color: red;
  line-height: 13px;
`;

export const LearnMoreViewWrapper = styled.div`
  border-radius: 25px;
  background: #CBC3F7;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 336px;
`;
