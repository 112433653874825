import styled from "styled-components/macro";
import { Link as LinkS } from "react-scroll";
import { PAGE_FONTS, WINDOW_WIDTH_CONSTRAINTS } from "../../constants";

export const Nav = styled.nav`
  background: #ffffff;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;

  align-items: center;

  height: 60px;
  /* margin-top: -80px; */
  top: 0;
  z-index: 10;
  font-size: 1rem;

  // TO DO: check what's happening with sticky
  position: sticky;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.LARGE_SCREENS_UP_LIMIT}) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;

  position: relative;
  height: 60px;
  width: 100%;

  padding: 0 24px;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    display: block;

    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);

    font-size: 1.8rem;

    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;

  list-style: none;
  text-align: center;

  margin-right: -22px;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    display: none;
  }
`;
export const NavLogoItem = styled.div`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%; /* Ensure it spans the navbar height */
  padding-left: 16px; /* Optional padding to align with your navbar */
`;

export const NavItem = styled.li`
  height: 40px;
`;

export const NavLinks = styled(LinkS)`
  color: #2b2e35;

  font-family: ${PAGE_FONTS.OSWALD_FONT};
  font-size: 25px;
  font-weight: 400;
  font-style: bold;

  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;

  cursor: pointer;

  &:active,
  :hover {
    // TO DO : Look whats happening here
    /* border-bottom: 3px solid #ffffff; */
    text-decoration: underline;
  }
`;
