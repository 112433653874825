import teo from "../photos/TeacherPortrets/teodor_iliev.jpg";
import dilqna from "../photos/TeacherPortrets/dilqna.png";
import nikolena from "../photos/TeacherPortrets/nikolena.png"
import ema from "../photos/TeacherPortrets/ema.png";
import viktor from "../photos/TeacherPortrets/viktor.png";
import misho from "../photos/TeacherPortrets/viktor.png"


 export const allTeachers = [
  {
    image: teo,
    name: "Теодор Илиев",
    description: "Математика, Информатика",
    learnMoreInfo: { info: "Теодор е основател на БГ Ментор и работи като софтуерен инженер. Преподава вече 6 години. Занимава се със състезателна математика от втори клас. Завършил е СМГ немската паралелка. Теодор е готов да помогне на всеки, който изпитва затруднения по математика и информатика.", price: "30лв. / 45 мин." },
  },
  {
    image: misho,
    name: "Михаил Тодоров",
    description: "Математика",
    learnMoreInfo: { info: "Михаил Тодоров ....", price: "25лв. / 45 мин." },
  },
  {
    image: dilqna,
    name: "Диляна Топузова",
    description: "Математика",
    learnMoreInfo: {
      info: "Диляна Топузова е завършила СМГ „Паисий Хилендарски“ с отличие. Следва архитектура в УАСГ. Преподава математика вече четвърта година в школата на БГ Ментор. За нея математиката е като редицата на Фибоначи - всяка следваща стъпка е резултат от сбора на предходните две.",
      price: "25лв. / 45 мин.",
    },
  },
  {
    image: nikolena,
    name: "Николена Пейчева",
    description: "Математика",
    learnMoreInfo: {
      info: "Николена Пейчева е преподавател по математика в 51 средно училище „Елисавета Багряна“. Завършила е Математика и информатика в направление Педагогика на обучението. Своя стаж е изкарала в СМГ Паисий Хилендарски. Разчита на съвременни методи за обучение, водейки се по индивидуалните потребности на всеки ученик. Основната ѝ цел е повишаване на мотивацията и надграждане на знанията на учениците.",
      price: "25лв. / 45 мин.",
    },
  },
  {
    image: ema,
    name: "Ема Евгениева",
    description: "Математика",
    learnMoreInfo: {
      info: "С над 4 години опит в екипа на БгМентор е и Ема. Тя следва Компютърни науки във ФМИ към СУ. Ема цели не само да предаде своите знания, а да изгради връзка с учениците. Смята че математиката е повече от просто учебен предмет - тя е ключът към развитието на аналитично мислене и постигането на високи резултати във всички сфери.",
      price: "25лв. / 45 мин.",
    },
  },
  {
    image: viktor,
    name: "Виктор Аргиров",
    description: "Английски",
    learnMoreInfo: {
      info: "Виктор е завършил своето образование във Великобритания и работи за Macmillan. Организира обучения за възрастни и по-конкретно учители по английски. По този начин помага за внедряването на дигитални продукти в преподаването. Също така е работил с младежи в НПО сектора, което го кара да избере пътя на преподавател по английски език.",
      price: "25лв. / 45 мин.",
    },
  },
];
