import React from "react";
import styled from "styled-components/macro";
import { PAGE_FONTS } from "../../constants";
import horizontalLogo from "../../assets/photos/logos/logo_horizontal.png"


const HorizontalLogo = styled.img`
  height: 40px; /* Control the height here to fit the navbar */
  width: auto; /* Maintain the aspect ratio */
  `;


const Logo = () => {
  return (
    <HorizontalLogo src={horizontalLogo} alt="Company Logo" />  );
};

export default Logo;
