import styled from "styled-components/macro";
import { PAGE_FONTS, WINDOW_WIDTH_CONSTRAINTS } from "../../constants";

export const StyledContactsContainer = styled.div`
  height: 60vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  position: relative;

  // show navbar for smaller screens
  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    z-index: 2;
    padding-top: 50px;
    flex-direction: column;
    height: 100vh;
  }

  // hide navbar for bigger screens
  @media screen and (min-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_UP_LIMIT}) {
    z-index: 11;
  }
`;

export const Info = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 80%;
  height: 100%;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    z-index: 2;
    padding: 0px 40px 0px;
    flex-direction: column;
    height: 80%;
  }
`;

export const MapsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  padding-top: 50px;
  padding-bottom: 50px;
`;

export const MapsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactsInfoItemWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContactData = styled.div`
  font-family: ${PAGE_FONTS.NUNITO_FONT};
  font-size: 18px;
  line-height: 18px;
  color: #0F0E25;

  white-space: break-word;
  overflow: hidden;
  verflow-wrap: break-word;
  text-overflow: ellipsis;

  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 8px;
`;

export const StyledContactsImage = styled.img`
  width: 70%;

  max-width: 550px;
  min-width: 200px;
  padding-top: 40px;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.LARGE_SCREENS_DOWN_LIMIT}) {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: 70%;
    height: auto;
  }
`;

export const ContactsImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  padding: 10px 20px;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    background-color: white;
    height: 40%;
  }
`;

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const FacebookLink = styled.a`
  color: #0F0E25;
`;